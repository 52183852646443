<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Expéditions</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par référence ou client" @change="changePage(1)" v-model="textFilter">
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline">
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="reset" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0 shipments-table">
            <div v-if="shipments.length === 0" class="card-body text-center">
              <h4 class="header-title">Aucune expédition.</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="shipments"
              no-top-border
              :show-actions="false"
              hover
              :link="(item) => `/shipments/${item.id}`"
            ></collection-table>

            <div v-if="paginatedData.pageCount > 1" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination :current-page="page" state="shipments" :display-per-page="false" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import formatsDate from '@/mixins/formatsDate'
import Pagination from '@/components/common/Pagination'

export default {
  components: {
    Pagination
  },
  mixins: [formatsDate],
  computed: {
    isLoading () {
      return this.$store.state.shipments.isLoading
    },
    cols () {
      return [
        {
          header: 'Date',
          property: 'createdAt',
          headerClass: 'col-date',
          method: (item) => {
            return this.$shortDate(item.shippedAt)
          }
        },
        {
          header: 'Numéro',
          property: 'reference'
        },
        {
          header: 'Commande',
          property: 'order.reference',
          method: (item) => {
            return get(item, 'order.reference')
          }
        },
        {
          header: 'Destinataire',
          property: 'customer.firstName',
          method: (item) => {
            return `${get(item, 'order.customer.firstName')} ${get(item, 'order.customer.lastName')}`
          }
        },
        {
          header: 'Articles',
          property: 'totalQuantity',
          method: (item) => {
            let total = 0

            if (item.shipmentItems && Array.isArray(item.shipmentItems)) {
              for (const shipmentItems of item.shipmentItems) {
                total += shipmentItems.quantity
              }
            }

            return total
          }
        }
      ]
    },
    paginatedData () {
      return this.$store.state.shipments.paginatedData
    },
    shipments () {
      return this.paginatedData.data
    },
    textFilter: {
      get () {
        return this.$store.state.shipments.textFilter
      },
      set (value) {
        this.$store.commit('shipments/SET_TEXT_FILTER', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.shipments.perPage
      },
      set (value) {
        this.$store.commit('shipments/SET_PER_PAGE', value)
      }
    },
    page () {
      return this.$store.state.shipments.page
    }
  },
  methods: {
    reset () {
      this.statusFilter = ''
      this.textFilter = ''
      this.changePage(1)
      this.$toasted.info('Les filtres sont réinitialisés')
    },
    async changePage (page) {
      await this.fetchShipments(page)
    },
    async fetchShipments (page) {
      await this.$store.dispatch('shipments/fetch', { page })
    }
  },
  async created () {
    await this.fetchShipments()
  }
}
</script>

<style lang="scss">
.shipments-table {
  .col-date {
    width: 220px;
  }
}
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
