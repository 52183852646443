<template>
  <tr>
    <td>
      <router-link target="_blank" :to="`/products?variantId=${item.orderItem.variantId}`">
        {{ productName }}
        <span v-if="variantName">- {{variantName }}</span>
        <br><small>SKU: {{ sku }}</small>
      </router-link>
    </td>
    <td class="text-center">{{ quantity }}</td>
  </tr>
</template>

<script>
import { get } from 'lodash-es'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    productName () {
      return get(this.item, 'orderItem.productName')
    },
    variantName () {
      return get(this.item, 'orderItem.variantName')
    },
    sku () {
      return get(this.item, 'orderItem.variant.sku')
    },
    quantity () {
      return get(this.item, 'quantity', 1)
    }
  }
}
</script>
